<!-- Digitalización Facturas -->

<template>
  <div class="fras_Digi mr-1">   
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{on}">

        <!-- Botón para abrir Finder Documentos -->
        <v-btn 
          :color="!show? '' : digitalizada ? 'green lighten-3' : 'red lighten-3'"
          v-on='on'
          v-on:click="btra_Mto({ accion:0 })"
          v-bind="$cfg.btra_cfg.standard"
          
          title="Digitalización de Facturas"
          :disabled="!show">
            DIG
        </v-btn>       
      </template>

      <div class="contenedor centrado">
        <v-sheet :elevation="4">

          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>
          </div> 
          
          <!-- Botoneras -->
          <div class="conflex pl-2 pt-2">
            <v-btn 
              v-bind="$cfg.btra_cfg.standard"
              :disabled="digitalizada"
              @click="guardar()">
                <v-icon>{{'mdi-content-save'}}</v-icon>
            </v-btn> 
          </div>

          <!-- Controles del Mto -->
          <div class="ctrls conflex contenedor max-width:1240px"> 
            <div class="columna" style="width:400px">
              <div class="conflex">
                <vllabel
                  style="flex: 0 0 60%" 
                  :label="schema.ctrls.digf_fh.label"
                  :txt="schema.ctrls.digf_fh.value|fechahr">              
                </vllabel>

                <vllabel
                  style="flex: 0 0 40%"
                  stilot="color:red" 
                  label="Estado"
                  :txt="digitalizada? 'Digitalizada' : ''">            
                </vllabel>
              </div>

              <div class="pt-2" style="display:flex">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.digf_usu.value"
                  :label="schema.ctrls.digf_usu.label"
                  readonly>      
                </v-text-field>
                
                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.digf_rec.value"
                  :label="schema.ctrls.digf_rec.label"            
                  :items="itemsRecepcion" 
                  item-value="name"
                  item-text="name"
                  :disabled="digitalizada">
                </v-select>
              </div>
            
              <v-textarea
                v-bind="$textarea"
                v-model="schema.ctrls.digf_obs.value"
                :label="schema.ctrls.digf_obs.label"     
                rows='2'
                no-resize
                :disabled="digitalizada">
              </v-textarea>
            </div>
          </div>
        </v-sheet> 
      </div>
    </v-dialog>    
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js";
  import { mixinMtoArray } from "@/mixins/mixinMtoArray.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
      
  export default {
    mixins: [mixinMto, mixinMtoArray],
    components: { base_Header },
    props: {
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}}, 
      tipo: { type: [String, Number], default: "1" }, 
      show: { type: [String, Boolean], default: "1" },    
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            digf_fh: { f:'digf_fh', label:'Fecha', value:""},
            digf_usu: { f:'digf_usu', label:'Usuario', value:""},
            digf_rec: { f:'digf_rec', label:'Recepción', value:"", ini:'Ordinario'},
            digf_obs: { f:'digf_obs', label:'Observaciones', value:""},
          }
        },
        api:'',
        stName:'stMfras_Digi',
      
        dialog:false,
        itemsRecepcion: []
      };
    },


    created() {
      this.ini_data();
    },


    computed: {
      digitalizada() {
        return this.accionRow.digf_fh? true : false
      }
    },
   

    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = "Digitalización de Facturas"; 
      
        // items adicionales
        this.itemsRecepcion= [
          { id:"0", name:"Ordinario"},
          { id:"1", name:"Certificado"},
          { id:"2", name:"Mensajeria"},
          { id:"3", name:"Otros"},
          { id:"4", name:"Firma Digital"},
        ];
      },


      // entro al pulsar el boton para mostrar el componente
      // guardo el id recibido de la fra en el schema
      /* openComp() {
        alert(this.actualDateTime())
        this.schema.ctrls.digf_fh.value= this.actualDateTime();
        this.btra_Mto({ accion:0 });
      }, */


      // al terminar de cargar el registro, si la factura aun no ha sido
      // digitalizada, inicializo schema
      record_cargado() {
        if (this.digitalizada) return;
      
        this.schema.ctrls.digf_fh.value= this.actualDateTime();
        this.schema.ctrls.digf_usu.value= this.usu;
      },


      // guardo datos de digitalización
      async guardar() {       
        let ctrls= this.ctrl2record_wo_compare(this.schema.ctrls);
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          ...ctrls, ...{
              accion:'digiFact', 
              tip:this.tipo,
              id:this.accionRow.id}
          }
        };
        console.log('args guardar: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult envio_emailGd: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
 
        this.$root.$alert.open('Datos guardados correctamente', 'success', 1800);

        // actualizo record de la fra seleccionada
        //this.$store.commit(this.padre + '/update_record', { opcion: 'guardar', record: apiResult.r[0], id: apiResult.r[0].id });
        this.$emit('reload');
        this.dialog= false;
      }
    }
  };
</script>
